// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import Link from '../../components/link';
import GlossaryLink from '../../components/glossary-link';
import route from '../../routes';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
    _12: ImageType,
    _13: ImageType,
    _14: ImageType,
    _15: ImageType,
    _16: ImageType,
  },
};

class Blog_2018_05_19_Robbers_Roost extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>I had a free weekend and met up with a group for Robber's Roost.</p>
        <h2>Mindbender</h2>
        <CanyonRatingCard rating="3AII" title="Mindbender" />
        <p>
          We started the day with Mindbender. Kind of a weird choice if you ask
          me. Turns out the decision to do the canyon was a bit of a
          misunderstanding but we did it anyway.
        </p>
        <p>
          Since this was a short, beginner-friendly canyon, we decided to spice
          it up a bit and <GlossaryLink>ghost</GlossaryLink> it. Assessing the
          situation, we determined that a <GlossaryLink>toggle</GlossaryLink>{' '}
          around a large <GlossaryLink>chockstone</GlossaryLink> would be the
          best way to accomplish this. We pulled it before{' '}
          <GlossaryLink>LAPAR</GlossaryLink> when we realized it was down
          climbable.
        </p>
        <Image
          caption="Geared up and ready to go. And the only picture with all four of us in it!"
          image={data._1}
        />
        <Image caption="Watching Nico on rappel" image={data._2} />
        <Image caption="Rigged toggle" image={data._3} />
        <p>
          After a couple of enjoyable down climbs and shallow pools, we arrived
          at the final rappel. We opted to inspect and use the existing{' '}
          <GlossaryLink>cairn anchor</GlossaryLink> instead of ghosting it with
          a <GlossaryLink>sand trap</GlossaryLink>. Why? I didn't want to have
          to move the cairn for an easy pull. However, after people were on
          rappel I realized that we should have used the sand trap. Immediately
          after the cairn anchor and before the actual drop, there was a
          perfectly contoured pothole. It would have been simple. Oh well.
          Another time.
        </p>
        <Image
          caption="Nico spotting Kyle on one of the many down climbs"
          image={data._4}
        />
        <Image caption="Kyle on the last rappel" image={data._5} />
        <Image caption="Last rappel looking up" image={data._6} />
        <Image image={data._7} />
        <p>
          A hot and scenic exit followed and before we knew it we were finished
          wondering what we should do next.
        </p>
        <h2>Chambers</h2>
        <CanyonRatingCard rating="3AIR" title="Chambers" />
        <p>
          We had planned on doing Chambers the next day but since we finished
          Mindbender quickly we opted to do Chambers that afternoon. Did you
          know that those canyons are only about 4 miles from each other but
          over an hour drive to get there?
        </p>
        <p>
          It was the afternoon by the time we repacked and hiked down to the
          bottom. Looking at the rain clouds over the Henry's, Richie got
          nervous and chose to hike back to the cars. Considering the conditions
          I opted to do the canyon and the others did as well. Richie{' '}
          <em>really</em> wanted to do Chambers and chose to back out at the
          last minute.
          <br />
          "No matter," I reassured him. We would do it again tomorrow.
        </p>
        <Image image={data._8} />
        <Image image={data._9} />
        <p>
          We squeezed and grunted through the top section and were blown away by
          the light rays illuminating the subway section.
        </p>
        <Image
          caption={
            <>
              Nico and Kyle <GlossaryLink>elevatoring</GlossaryLink> down into
              the next chamber
            </>
          }
          image={data._10}
        />
        <Image
          caption={
            <>
              Nico in the <GlossaryLink>subway</GlossaryLink> section
            </>
          }
          image={data._11}
        />
        <p>
          We descended the boulders into the lower section. At the crux we
          noticed someone had scratched an arrow into the wall.
        </p>
        <Image
          caption="Thank you for your good intentions, graffiti artist. That was
          idiotic. I hope you regret what you did and that you will never do it
          again."
          image={data._12}
        />
        <p>
          We soon crawled through the exit crack and hiked back up to the cars,
          satisfied and hungry.
        </p>
        <Image caption="Kyle coming out of the exit" image={data._13} />
        <Image
          caption="Dirty Devil River on the exit. On the horizon Factory Butte is visible"
          image={data._14}
        />
        <h2>Chambers Again</h2>
        <p>
          The next day Richie and I descended Chambers again while Kyle and Nico
          did Little Bull.
        </p>
        <p>
          When I had done it the day before I was definitely regretting my
          decision to do it. It had been a while since I did a skinny canyon
          that didn't involve <GlossaryLink>stemming</GlossaryLink> over it. I
          remember a few times I really didn't want to be there and told myself
          I would never do this canyon again. Apparently 12 hours was long
          enough for that feeling to go away.
        </p>
        <p>
          I'm actually glad I went in a second time for the following reasons:
        </p>
        <ol>
          <li>
            Richie had been looking forward to doing it and I felt it was my
            duty to ensure he got to go.
          </li>
          <li>
            Someone had lost their helmet in the crux and we wanted to get it
            out.
          </li>
          <li>
            I picked a different route and it was way better the second time. I
            also completely overcame my fear of the canyon.
          </li>
        </ol>
        <Image caption="Richie in the subway" image={data._15} />
        <p>
          Richie is much skinnier than the rest of us so he flew through the
          tight sections. It was kind of cool to see how fast he went. We blazed
          through the upper section and quickly arrived at the crux. I brought a{' '}
          <GlossaryLink>talon</GlossaryLink>,{' '}
          <GlossaryLink>SQWUREL</GlossaryLink>, and a couple of{' '}
          <GlossaryLink>slings</GlossaryLink> to fish the helmet out. After a
          few minutes of struggling, Richie hooked a hidden helmet strap and
          reeled it up. Success!
        </p>
        <p>
          While he was fishing, I attempted to get rid of the arrows that had
          been scratched into the rock. I did the mud trick where you rub some
          mud on the graffiti and hope it goes away. Didn't work - the scratches
          were just too deep.
        </p>
        <p>
          Again we reached the end of the canyon and headed back to the cars.
          Nico and Kyle arrived just as we crested the plateau.
        </p>
        <h2>Zero Gravity</h2>
        <CanyonRatingCard rating="3BIR" title="Zero Gravity" />
        <p>
          We opted to hit Zero Gravity in the afternoon. I needed to get back
          home that night and it was on the way. The others agreed to accompany
          me.
        </p>
        <p>
          Zero Gravity was my{' '}
          <Link to={route.blog_2012_04_28_my_story}>first canyon</Link> so it
          holds a special place for me. It had been 6 years and over one hundred
          canyons since I did it last.
        </p>
        <Image caption="Stemming over some water in Zero G" image={data._16} />
        <p>
          It was very nostalgic revisiting the canyon. This time I chose to go
          high in a lot of places I didn't realize it was possible to go high
          before. I went down at the{' '}
          <GlossaryLink id="semi-keeper-pothole">semi-keeper</GlossaryLink>{' '}
          because I wanted to see how difficult it would be to climb. Much
          easier than I remember.
        </p>
        <p>
          When we got to the end I was still too scared to go under the
          chockstone. One member of the group who is slightly bigger in the
          chest than I am made it though so next time I'll give it a try.
        </p>
        <p>
          That canyon is just as amazing as I remember and I can't wait to go
          there again. It's amazing how different it is from the rest of the
          canyons in its vicinity. It really is quite the anomaly.
        </p>
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-05-19-robbers-roost')(
  Blog_2018_05_19_Robbers_Roost
);

export const query = graphql`
  query {
    _1: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/3.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/5.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/6.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/7.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/8.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/9.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _10: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/10.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/11.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _12: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/12.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _13: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/13.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _14: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/14.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _15: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/15.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _16: file(
      relativePath: { eq: "images/blog/2018-05-19-robbers-roost/16.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
